import httpClient from '@/httpClient';

export function gcodes (state) {
  return state.gcodes;
}

export function gcodeDetail (state) {
    return state.gcodeDetail;
}

// TODO: gcode should be downloaded in such way, that authorization token is not send in "link", but in authorization header
export function gcodeDownloadLink (state, getters, rootState, rootGetters) {
  return (gcodeId) => {
    return httpClient.defaults.baseURL + 'files/' + gcodeId + '/content/?authorization=' + rootGetters['account/accessToken'];
  }
}

export function videos (state) {
  return state.videos;
}

export function videoDetail (state) {
  return state.videoDetail;
}

export function videoDownloadLink (state, getters, rootState, rootGetters) {
  // onlyStream params means we want video for streaming, not for downloading
  return (gcodeId, onlyStream, contentType) => {
    if (['photo', 'photo-stripe', 'video'].includes(contentType)) {
      return httpClient.defaults.baseURL + 'print-job-videos/download/' + gcodeId + '/' + contentType + '/?' + (onlyStream ? '' : 'download=1&') + 'authorization=' + rootGetters['account/accessToken'];
    }
  }
}

export function fileLabels (state) {
  if (Array.isArray(state?.fileLabels)) {
    let data = structuredClone(state.fileLabels);  // deep copy data to avoid vuex strict mode error

    data.sort((a, b) => {
      let nameA = a.name.toUpperCase();
      let nameB = b.name.toUpperCase();
      if (nameA < nameB) { return -1; }
      if (nameA > nameB) { return 1; }
      return 0;
    });

    let findChildren = function(parent_id, array, nest) {
      let children = [];
      array.forEach(child => {
        if (child.parent_id == parent_id) {
          child.nestedCount = nest;
          children.push(child);
          if (child.children_count > 0) {
            let subChildren = findChildren(child.id, array, nest+1);
            subChildren.forEach(subSubChildren => {
              children.push(subSubChildren);
            });
          }
        }
      });
      return children;
    };

    return findChildren(null, data, 0);
  }
  return [];
}

export function fileLabelById (state) {
  return (id) => {
    if (state?.fileLabels?.length > 0) {
      for (let i = 0; i < state.fileLabels.length; i++) {
        if (state.fileLabels[i].id == id) {
          return state.fileLabels[i];
        }
      }  
    }
    return null;
  }
}

export function printJobs (state) {
  return state.printJobs;
}

export function printDialogGcodes (state) {
  return state.printDialogGcodes;
}
