<template>

  <svg v-if="name=='camera-on'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>

  <svg v-else-if="name=='camera-off'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 24l15-22" />
  </svg>

  <svg v-else-if="name=='light-on'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
  </svg>

  <svg v-else-if="name=='light-off'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 22l15-22" />
  </svg>

  <svg v-else-if="name=='arrows-expand'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
  </svg>

  <svg v-else-if="name=='dots-vertical'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Dots" fill="currentColor">
        <rect id="Rectangle-Copy-3" x="11" y="5" width="3" height="3"></rect>
        <rect id="Rectangle-Copy-4" x="11" y="11" width="3" height="3"></rect>
        <rect id="Rectangle-Copy-5" x="11" y="17" width="3" height="3"></rect>
      </g>
    </g>
  </svg>

  <svg v-else-if="name=='dots-horizontal'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
  </svg>

  <svg v-else-if="name=='search'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
  </svg>

  <svg v-else-if="name=='home'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
  </svg>

  <svg v-else-if="name=='slash'" class="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
  </svg>

  <svg v-else-if="name=='user'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
  </svg>

  <svg v-else-if="name=='user-group'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
  </svg>

  <svg v-else-if="name=='photograph'" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
  </svg>

  <svg v-else-if="name=='lock-open'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
  </svg>

  <svg v-else-if="name=='arrow-narrow-left'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
  </svg>

  <svg v-else-if="name=='animate-spin-circle'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
  </svg>

  <svg v-else-if="name=='exclamation'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
  </svg>

  <svg v-else-if="name=='question-mark-circle'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
  </svg>

  <svg v-else-if="name=='refresh'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
  </svg>

  <svg v-else-if="name=='cog'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
  </svg>

  <svg v-else-if="name=='information-circle'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
  </svg>

  <svg v-else-if="name=='chevron-down'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
  </svg>

  <svg v-else-if="name=='chevron-left'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
  </svg>

  <svg v-else-if="name=='chevron-right'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
  </svg>

  <svg v-else-if="name=='chevron-up'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
  </svg>

  <svg v-else-if="name=='directory'" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="768" height="768" viewBox="0 0 768 768" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M736 608v-352c0-26.496-10.784-50.56-28.128-67.872s-41.376-28.128-67.872-28.128h-270.88l-54.496-81.76c-5.824-8.64-15.552-14.24-26.624-14.24h-160c-26.496 0-50.56 10.784-67.872 28.128s-28.128 41.376-28.128 67.872v448c0 26.496 10.784 50.56 28.128 67.872s41.376 28.128 67.872 28.128h512c26.496 0 50.56-10.784 67.872-28.128s28.128-41.376 28.128-67.872zM672 608c0 8.832-3.552 16.8-9.376 22.624s-13.792 9.376-22.624 9.376h-512c-8.832 0-16.8-3.552-22.624-9.376s-9.376-13.792-9.376-22.624v-448c0-8.832 3.552-16.8 9.376-22.624s13.792-9.376 22.624-9.376h142.88l54.496 81.76c6.144 9.184 16.192 14.176 26.624 14.24h288c8.832 0 16.8 3.552 22.624 9.376s9.376 13.792 9.376 22.624z"></path>
  </svg>

  <svg v-else-if="name=='plus'" xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="768" height="768" viewBox="0 0 768 768" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M607.5 415.5h-192v192h-63v-192h-192v-63h192v-192h63v192h192v63z"></path>
  </svg>

  <svg v-else-if="name=='printer'" version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024" fill="currentColor">
    <path d="M249.428 588.93h525.157v315.089h-525.157z"></path>
    <path d="M249.428 494.402h525.157v63.026h-525.157z"></path>
    <path d="M39.36 935.537h945.28v63.026h-945.28z"></path>
    <path d="M585.516 336.864c0 34.752-28.259 63.026-63.026 63.026h-273.078v63.026h273.078c69.494 0 126.032-56.539 126.032-126.032z"></path>
    <path d="M984.64 95.288h-273.078v-42.008h-189.056v42.008h-483.146v63.026h483.146v42.008l63.020 105.034h63.026l63.020-105.034v-42.008h273.078z"></path>
  </svg>

  <svg v-else-if="name=='eye'" width="640" height="640" version="1.1" viewBox="0 0 640 640" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.4 320c59.516-114.936 177.544-192.128 313.6-192.128s254.084 77.192 312.676 190.169l0.924 1.959c-59.516 114.936-177.544 192.128-313.6 192.128s-254.084-77.192-312.676-190.169l-0.924-1.959zM320 448c70.692 0 128-57.308 128-128s-57.308-128-128-128v0c-70.692 0-128 57.308-128 128s57.308 128 128 128v0zM320 384c-35.346 0-64-28.654-64-64s28.654-64 64-64v0c35.346 0 64 28.654 64 64s-28.654 64-64 64v0z"></path>
  </svg>

  <svg v-else-if="name=='check'" width="448" height="448" version="1.1" viewBox="0 0 448 448" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"  d="M417.75 141.5c0 6.25-2.5 12.5-7 17l-215 215c-4.5 4.5-10.75 7-17 7s-12.5-2.5-17-7l-124.5-124.5c-4.5-4.5-7-10.75-7-17s2.5-12.5 7-17l34-34c4.5-4.5 10.75-7 17-7s12.5 2.5 17 7l73.5 73.75 164-164.25c4.5-4.5 10.75-7 17-7s12.5 2.5 17 7l34 34c4.5 4.5 7 10.75 7 17z"></path>
  </svg>

  <svg v-else-if="name=='trash'" width="512" height="512" version="1.1" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M64 160v320c0 17.6 14.4 32 32 32h288c17.6 0 32-14.4 32-32v-320h-352zM160 448h-32v-224h32v224zM224 448h-32v-224h32v224zM288 448h-32v-224h32v224zM352 448h-32v-224h32v224z"></path>
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M424 64h-104v-40c0-13.2-10.8-24-24-24h-112c-13.2 0-24 10.8-24 24v40h-104c-13.2 0-24 10.8-24 24v40h416v-40c0-13.2-10.8-24-24-24zM288 64h-96v-31.599h96v31.599z"></path>
  </svg>

  <svg v-else-if="name=='camera'" version="1.1" xmlns="http://www.w3.org/2000/svg" width="768" height="768" viewBox="0 0 768 768" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M704 286.176v195.648l-136.96-97.824zM96 128c-26.496 0-50.56 10.784-67.872 28.128s-28.128 41.376-28.128 67.872v320c0 26.496 10.784 50.56 28.128 67.872s41.376 28.128 67.872 28.128h352c26.496 0 50.56-10.784 67.872-28.128s28.128-41.376 28.128-67.872v-97.824l173.408 123.872c14.368 10.272 34.368 6.944 44.64-7.424 4.032-5.696 5.984-12.256 5.952-18.624v-320c0-17.664-14.336-32-32-32-6.976 0-13.44 2.24-18.592 5.952l-173.408 123.872v-97.824c0-26.496-10.784-50.56-28.128-67.872s-41.376-28.128-67.872-28.128zM96 192h352c8.832 0 16.8 3.552 22.624 9.376s9.376 13.792 9.376 22.624v320c0 8.832-3.552 16.8-9.376 22.624s-13.792 9.376-22.624 9.376h-352c-8.832 0-16.8-3.552-22.624-9.376s-9.376-13.792-9.376-22.624v-320c0-8.832 3.552-16.8 9.376-22.624s13.792-9.376 22.624-9.376z"></path>
  </svg>

  <svg v-else-if="name=='play'" width="59px" height="66px" viewBox="0 0 73 80" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="play" transform="translate(-1310.000000, -513.000000)" fill="currentColor" fill-rule="nonzero">
            <g id="Symbols" transform="translate(535.000000, 513.000000)">
                <g id="Regular-S" transform="translate(774.100000, 0.000000)">
                    <path d="M5.88048,65.03905 C7.58948,65.03905 9.00548,64.40429 10.66558,63.42772 L54.61098,38.1348 C58.17538,36.0351 59.34728,34.7168 59.34728,32.5195 C59.34728,30.3223 58.17538,29.0039 54.61098,26.9043 L10.66558,1.6113 C9.00548,0.6348 7.58948,4.97379915e-14 5.88048,4.97379915e-14 C2.80428,4.97379915e-14 0.9,2.3926 0.9,6.0058 L0.9,59.03319 C0.9,62.64648 2.80428,65.03905 5.88048,65.03905 Z M8.22418,56.6406 C8.02888,56.6406 7.93128,56.543 7.93128,56.3476 L7.93128,8.6914 C7.93128,8.4961 8.02888,8.3984 8.22418,8.3984 C8.32188,8.3984 8.41958,8.4473 8.56598,8.4961 L49.67928,32.2754 C49.82578,32.3242 49.87458,32.373 49.87458,32.5195 C49.87458,32.666 49.82578,32.7148 49.67928,32.7637 L8.56598,56.543 C8.41958,56.5918 8.32188,56.6406 8.22418,56.6406 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
  </svg>
  
  <svg v-else-if="name=='queue-list'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 12h16.5m-16.5 3.75h16.5M3.75 19.5h16.5M5.625 4.5h12.75a1.875 1.875 0 010 3.75H5.625a1.875 1.875 0 010-3.75z" />
  </svg>

  <svg v-else-if="name=='bars-4'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
  </svg>

  <svg v-else-if="name=='filament'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" fill="none">
    <path d="M12 2.001c-5.523 0-10 4.477-10 10 0 1.853.504 3.587 1.383 5.075V11.98A8.617 8.617 0 0 1 12 3.383c7.653 0 11.51 9.296 6.094 14.71-4.11 4.11-10.45 2.879-13.27-1.3v2.164a9.97 9.97 0 0 0 7.181 3.041c5.523 0 10-4.477 10-10s-4.477-10-10-10zm2.647 7.353c-2.351-2.352-6.389-.678-6.389 2.647 0 3.324 4.038 4.998 6.389 2.647a3.743 3.743 0 0 0 0-5.293zM12 9.307a2.693 2.693 0 1 0 0 5.387 2.693 2.693 0 0 0 0-5.387z" />
    <path d="M12 3.383c7.653 0 11.51 9.296 6.094 14.71-4.11 4.11-10.45 2.879-13.27-1.3v4.437a.718.718 0 1 1-1.436 0v-9.252a8.617 8.617 0 0 1 8.617-8.597zm2.647 5.971c-2.351-2.352-6.389-.678-6.389 2.647 0 3.324 4.038 4.998 6.389 2.647a3.743 3.743 0 0 0 0-5.293z" />
  </svg>

  <svg v-else-if="name=='pause'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
  </svg>

  <svg v-else-if="name=='stop'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 7.5A2.25 2.25 0 0 1 7.5 5.25h9a2.25 2.25 0 0 1 2.25 2.25v9a2.25 2.25 0 0 1-2.25 2.25h-9a2.25 2.25 0 0 1-2.25-2.25v-9Z" />
  </svg>

  <svg v-else-if="name=='nozzle'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="black">
    <path d="M7,2H17V8H19V13H16.5L13,17H11L7.5,13H5V8H7V2M10,22H2V20H10A1,1 0 0,0 11,19V18H13V19A3,3 0 0,1 10,22Z"></path>
  </svg>

  <svg v-else-if="name=='bed'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 303 278.615" stroke-width="1.5" stroke="currentColor">
    <g transform="translate(-657 -275.026)">
        <rect fill="none" width="303" height="56" rx="20" transform="translate(657 447)" stroke-width="22"></rect>
        <g class="bedheatwaves" transform="translate(0 16.022)">
          <path d="M731.724,383.843c-3.752-14.051-32.834-23.419-24.86-47.558s23.453-33.147,16.886-68.455" transform="translate(2.935 4)" stroke-linecap="round" stroke-width="22"></path>
          <path d="M731.724,383.843c-3.752-14.051-32.834-23.419-24.86-47.558s23.453-33.147,16.886-68.455" transform="translate(60.935 4)" stroke-linecap="round" stroke-width="22"></path>
          <path d="M731.724,383.843c-3.752-14.051-32.834-23.419-24.86-47.558s23.453-33.147,16.886-68.455" transform="translate(118.935 4)" stroke-linecap="round" stroke-width="22"></path>
          <path d="M731.724,383.843c-3.752-14.051-32.834-23.419-24.86-47.558s23.453-33.147,16.886-68.455" transform="translate(176.935 4)" stroke-linecap="round" stroke-width="22"></path>
        </g>
        <path fill="none" stroke-width="22" d="M0,0H28a0,0,0,0,1,0,0V27a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V0A0,0,0,0,1,0,0Z" transform="translate(684 506)"></path>
        <path fill="none" stroke-width="22" d="M0,0H28a0,0,0,0,1,0,0V27a5,5,0,0,1-5,5H5a5,5,0,0,1-5-5V0A0,0,0,0,1,0,0Z" transform="translate(904 506)"></path>
    </g>
  </svg>

  <svg v-else-if="name=='moon'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z" />
  </svg>

  <svg v-else-if="name=='sun'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" />
  </svg>

  <svg v-else-if="name=='bolt'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
  </svg>

  <svg v-else-if="name=='paper-airplane'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
  </svg>

  <svg v-else-if="name=='console'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="m6.75 7.5 3 2.25-3 2.25m4.5 0h3m-9 8.25h13.5A2.25 2.25 0 0 0 21 18V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v12a2.25 2.25 0 0 0 2.25 2.25Z" />
  </svg>

  <svg v-else-if="name=='code-bracket-square'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9.75 16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z" />
  </svg>

  <svg v-else-if="name=='power'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9" />
  </svg>

  <svg v-else-if="name=='x-circle'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
  </svg>

  <svg v-else-if="name=='pencil'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
  </svg>

  <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path fill="#000" d="M6 18L18 6M6 6l12 12" />
  </svg>

</template>

<script>
export default {
  name: 'uiIcon',
  props: ['name']
}
</script>
