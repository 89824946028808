import moment from 'moment';

const makePlural = (value, base) => {
  return value === 0 || value > 1 ? `${base}s` : base;
};

export default {

  dateTimeFormat: (date) => {
    return moment(date).format('D.M.YYYY HH:mm:ss');
  },

  simpleTimeFormat: (date) => {
    return moment(date).format('HH:mm');
  },

  humanDateFormat: (date) => {

    const base = moment(date);
    const diffBase = moment();
    const days = diffBase.diff(base, 'days');
    const hours = diffBase.diff(base, 'hours');
    const minutes = diffBase.diff(base, 'minutes');
    const seconds = diffBase.diff(base, 'seconds');

    let result = 'ERROR counting datetime';

    if (days > 10) {
      result = moment(date).format('D.M.YYYY');
    } else if (days <= 10 && days > 0) {
      result = `${days} ${makePlural(days, 'day')} ago`;
    } else if (hours <= 24 && hours > 0) {
      result = `${hours} ${makePlural(hours, 'hour')} ago`;
    } else if (minutes <= 60 && minutes > 0) {
      result = `${minutes} ${makePlural(minutes, 'minute')} ago`;
    } else if (seconds <= 60) {
      result = 'seconds ago';
    }

    return result;
  },

  timespan: (seconds, showSeconds=false) => {
    if (seconds === null || seconds === undefined) {
      return 'N/A';
    }
    const base = moment(0);
    const diffBase = moment(0).add(seconds, 'seconds');
    const hours = diffBase.diff(base, 'hours');
    const minutes = diffBase.diff(base, 'minutes') % 60;
    const secs = diffBase.diff(base, 'seconds') % 60;

    let result = '';

    // show hours optionaly
    if (hours > 0) {
      result += `${hours} ${makePlural(hours, 'hour')}`;
    }

    // show minutes optionaly
    if (hours || (!hours && minutes)) {
      result += result == '' ? '' : ', ';
      result += `${minutes} ${makePlural(minutes, 'minute')}`;
    }

    // show seconds optionaly
    if (!hours || showSeconds) {
      result += result == '' ? '' : ', ';
      result += `${secs} ${makePlural(secs, 'second')}`;
    }

    return result;
  },

  humanFileSize: (size) => {
    if (!size || size <= 0 ) {
      return '0 kB';
    }
    var i = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  },

  previewImageExists: (listOfPreviewImages) => {
    return (listOfPreviewImages?.length > 0);
  },

  primaryPreviewImage: (listOfPreviewImages) => {
    let image = null;
    if (listOfPreviewImages?.length > 0) {
      // find image marked as main
      image = listOfPreviewImages.find((el) => {
        return (el.main == true);
      });
      if (!image) {
        image = listOfPreviewImages[0];
      }
    }
    return image;
  },

  secondsToPrintTime: (seconds) => {
    if (seconds === null || seconds === undefined) {
      return 'N/A';
    }
    const base = moment(0);
    const diffBase = moment(0).add(seconds, 'seconds');
    const hours = diffBase.diff(base, 'hours');
    const minutes = diffBase.diff(base, 'minutes') % 60;
    const secs = diffBase.diff(base, 'seconds') % 60;

    let result = '';

    // show hours optionaly
    if (hours > 0) {
      result += `${hours}h`;
    }

    // show minutes optionaly
    if (hours || (!hours && minutes)) {
      result += result == '' ? '' : ' ';
      result += `${minutes}m`;
    }

    // show seconds optionaly
    if (!hours && minutes < 1) {
      result += result == '' ? '' : ' ';
      result += `${secs}s`;
    }

    return result;
  },

  milimitersToHumanReadable: (mms) => {
    if (mms === null || mms === undefined) {
      return 'N/A';
    }

    const meters = Math.floor(mms / 1000);
    const centimeters = Math.round((mms % 1000) / 10);
    const millimeters = Math.round(mms % 10);

    let result = '';

    // show meters optionaly
    if (meters > 0) {
      result += `${meters}m`;
    }

    // show centimeters optionaly
    if (centimeters > 0 || meters > 0) {
      result += ` ${centimeters}cm`;
    }

    // show millimeters optionaly
    if (millimeters > 0 && centimeters == 0 && meters == 0) {
      result += ` ${millimeters}mm`;
    }

    return result;
  },

  gramsToHumanReadable: (grams) => {
    if (grams === null || grams === undefined) {
      return 'N/A';
    }

    grams = Math.round(grams);

    return `${grams}g`;
  }
};
