<template>
  
  <template v-if="printer">

    <div v-if="printerState.text == 'SPECIAL-LOADING-STATE'">
      <ui-icon
        name="animate-spin-circle"
        class="animate-spin h-5 w-5"
      />
    </div>

    <div class="flex" v-else>
      <ui-indicator :color="printerState.color">{{ printerState.text }}</ui-indicator>
      <ui-button v-if="printerState.showMoreButton && printer.deviceType == 'moonraker'" tiny @click.stop.prevent="showPrinterErrorStateDialog()" icon="search" class="h-5 ml-1" color="success" />
    </div>

  </template>

  <ui-confirm-dialog
    ref="printerStateErrorConfirmDialog"
    :headerText="'Printer error: ' + printer?.klipperPrinterInfo?.state"
    :confirmBtnText="'RESTART'"
    :dismissBtnText="$t('components.modal.close')"
    :autoCloseOnConfirm="false"
    @onConfirm="restartKlipperPrinter()"
    :loading="isDialogLoading"
  >
    <p class="whitespace-pre">{{ this.printer?.klipperPrinterInfo?.state_message }}</p>
  </ui-confirm-dialog>
</template>

<script>
import uiIndicator from '@/components/ui/uiIndicator.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiConfirmDialog from '@/components/ui/uiConfirmDialog.vue';
import uiIcon from '@/components/ui/uiIcon.vue';

export default {
  name: 'PrinterState',

  components: {
    uiIndicator,
    uiButton,
    uiConfirmDialog,
    uiIcon
  },

  props: {
    printer: {
      type: Object,
      default: null,
      required: true
    }
  },

  computed: {
    printerState() {
      let state = { text: this.printer.printerState, color: 'danger' };

      if (this.printer.IS_CHANGING_STATE || this.printer.printerState == 'STATE_UNKNOWN' || this.delta > 10000) {
        state = { text: 'SPECIAL-LOADING-STATE', color: null, showMoreButton: false };
      } else if (this.printer.printerState == 'NOT_CONNECTED_TO_PROXY_SERVER') {
        state = { text: this.$t('views.printer.stateOffline'), color: 'default', showMoreButton: false };
      } else if (this.printer.printerState == 'READY_TO_PRINT') {
        state = { text: this.$t('views.printer.stateReady'), color: 'success', showMoreButton: false };
      } else if (this.printer.printerState == 'OCTOPRINT_PERMISSION_DENIED') {
        state = { text: this.$t('views.printer.stateDenied'), color: 'danger', showMoreButton: false };
      } else if (!this.printer.isDeviceConnectedToInternet) {
        state = { text: this.$t('views.printer.stateOffline'), color: 'default', showMoreButton: false };
      } else if (this.printer.printerState == 'PRINTER_NOT_PAIRED') {
        state = { text: this.$t('views.printer.stateDisconnected'), color: 'warning', showMoreButton: false };
      } else if (this.printer.printerState == 'PRINTING') {
        state = { text: this.$t('views.printer.statePrinting'), color: 'primary', showMoreButton: false };
      } else if (this.printer.printerState == 'PAUSED') {
        state = { text: this.$t('views.printer.statePaused'), color: 'danger', showMoreButton: false };
      } else if (this.printer.printerState == 'ERROR') {
        state = { text: this.$t('views.printer.stateError'), color: 'danger', showMoreButton: true };
      }
      return state;
    }
  },

  data: () => ({
    delta: 0,
    isDialogLoading: false
  }),

  mounted: function() {
    this.pollNow();
  },

  methods: {
    pollNow: function() {
      if (document.body.contains(this.$el)) {
        this.delta = Date.now() - this.printer.DATETIME;

        setTimeout(() => {
          this.pollNow();
        }, 500);
      }
    },

    async restartKlipperPrinter() {
      this.isDialogLoading = true;
      await this.$store.dispatch('printers/executeControlCommand', { printerId: this.printer?.id, action: 'send_command', obj: {commands: ['FIRMWARE_RESTART']} });
      setTimeout(() => {
        this.$refs.printerStateErrorConfirmDialog.close();
        this.isDialogLoading = false;
      }, 2000);
    },

    async showPrinterErrorStateDialog() {
      await this.$store.dispatch('printers/loadKlipperPrinterInfo', { printerId: this.printer.id });
      await this.$refs.printerStateErrorConfirmDialog.showDialog();
    }
  }
}
</script>
