<template>
  <div ref="appHeader" >
    <app-header />
  </div>

  <main :style="{paddingTop: headerHeight}">
    <slot></slot>
  </main>

  <app-footer />
</template>

<script>
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'AuthorizedLayout',

  components: {
    AppHeader,
    AppFooter
  },

  data: () => ({
    headerHeight: '',
  }),

  mounted: function() {
    this.getHeaderHeight()
  },

  methods: {
    getHeaderHeight() {
      this.headerHeight = this.$refs.appHeader.children[0].clientHeight + 'px';
    },
  }
}
</script>

<style>
main {
  @apply max-w-7xl mx-auto sm:px-6 lg:px-8 pt-16;
}
</style>
