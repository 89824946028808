import store from './store';
import router from './router';
import ErrorService from './errorService';
import Axios, { CanceledError } from 'axios';
import { NetworkOfflineError, ForbiddenError, ServerMaintenanceError } from './errors';


/*
  Following functionality is responsible for reloading application (browser reload) when it's long time
  from last http request or application was not reloaded for long time.
  This should help to prevent some application "stucks" or to force update application,
  because application is updated only on window reload - and also check for new version
  is only initiated with service worker only on application start.
*/
const FIRST_INSTANCE_REQUEST_DATETIME = Date.now();
let LAST_HTTP_REQUEST_DATETIME = Date.now();

async function reloadAppAfterLongInactivity() {
  if (
    (Date.now() - LAST_HTTP_REQUEST_DATETIME) > 1000 * 60 * 60  // reload application once there was no request in last 60 minutes
    || (Date.now() - FIRST_INSTANCE_REQUEST_DATETIME) > 1000 * 60 * 60 * 12  // reload application when it's no reloaded longer than 12 hours
  ) {
    location.reload();

    // let's wait for app to be reloaded
    await new Promise(resolve => setTimeout(resolve, 10000));
  }
  LAST_HTTP_REQUEST_DATETIME = Date.now();
}


const httpClient = Axios.create({
  baseURL: new URL(process.env.VUE_APP_API_PATH_PRIVATE, process.env.VUE_APP_API_BASE_URL).href
});

httpClient.interceptors.request.use((config) => {  
  const controller = new AbortController();

  // should be request authorized?
  if (config.authorizeRequest) {
    // check whether we hava accessToken & refreshToken, otherwise logout/cancel, something about authorization is wrong
    if (store.getters['account/accessToken'] && store.getters['account/refreshToken']) {
      config.headers['Authorization'] = 'Bearer ' + store.getters['account/accessToken'];
    } else {
      controller.abort(); // abort request, don't actually request api at all
      router.push({ name: 'Logout' });
    }
  }

  return {
    ...config,
    signal: controller.signal
  }
});

httpClient.interceptors.response.use(
  async function (response) {
    await reloadAppAfterLongInactivity();
    return response;
  },
  function (error) {    
    try {

      if (error instanceof CanceledError) {
        // it's cancelled request - see httpClient.interceptors.request above

      } else if (!error.response) {
        // no response - is it offline?
        throw new NetworkOfflineError('No internet', error);

      } else if (error.response.status == 403) {
        // request forbidden
        throw new ForbiddenError('Request forbidden', error);

      } else if (error.response.status == 503) {
        // server is under maintenance
        throw new ServerMaintenanceError('Server under maintenance', error);

      } else if (error.response.status == 401) {
        // authorization failed
        if (['Login'].includes(router.currentRoute.value.name)) {
          // HTTP 401 is correct and is handled by its component
        }
        else if (error.config.RETRY == true) {
          // it's retry request already, so logout - refresh token is probably not valid
          router.push({ name: 'Logout' });
        } else {
          // try to get new access token
          return httpClient.post('/tokens/refresh/', { refresh: store.getters['account/refreshToken'] }, { RETRY: true }).then(function (response) {
            store.commit('account/setAccessToken', response.data.access); // store new access token for other requests
            store.dispatch('account/loadDefaultData'); // re-load default user data
            return httpClient(error.config);
          });
        }
      }

      return Promise.reject(error);

    } catch (e) {
      // uknown error
      ErrorService.onError(e, error);
      return Promise.reject(e);
    }
  }
);

export default httpClient;
