export function setUser(state, val) {
  if (val) {
    state.isAuthenticated = true;
  } else {
    state.isAuthenticated = false;
  }
  state.user = val;
}

export function setGroups(state, val) {
  state.groups = val;
}

export function setApiKeys(state, val) {
  state.apiKeys = val;
}

export function setCurrentGroupId(state, val) {
  if (val) {
    localStorage.setItem('currentGroupId', val);
  } else {
    localStorage.removeItem('currentGroupId');
  }
}

export function setAccessToken(state, val) {
  if (val) {
    localStorage.setItem('accessToken', val);
  } else {
    localStorage.removeItem('accessToken');
  }
}

export function setRefreshToken(state, val) {
  if (val) {
    localStorage.setItem('refreshToken', val);
  } else {
    localStorage.removeItem('refreshToken');
  }
}

export function setDiscourseRegistered(state, val) {
  state.discourseRegistered = val;
}
