<template>
  <ui-modal :show="show" @close="close()" data-title="PrintDialog" :title="$t('components.printDialog.title')">
    <div class="min-h-96">

      <!-- Uploading to printer -->
      <div v-if="uploadingFileToPrinter" class="flex flex-col justify-center w-full h-60 dark:bg-gray-900">
        <ui-loader />
        <div class="pt-2 text-center text-sm">
          {{ $t('components.printDialog.uploadingText') }}
          <div class="italic text-xs mt-2">{{ $t('components.printDialog.uploadingDescription') }}</div>
        </div>
      </div>

      <div v-else>

        <!-- FILES -->
        <div>
          <div v-if="showFilesList" class="flex">
            <h3 class="grow">{{ $t('components.printDialog.choosePrintFile') }}</h3>
            <ui-button v-if="selectedGcode" small icon="chevron-left" @click.stop="showFilesList=false">{{ $t('components.printDialog.backBtnTxt') }}</ui-button>
          </div>
          
          <div class="border border-1 border-gray-300 mt-2 rounded">

            <!-- file selected -->
            <div v-if="!showFilesList" class="relative flex flex-row cursor-pointer" @click.stop="showFilesList=true">
              <div class="absolute right-1 bottom-1 hidden sm:block"><ui-button small icon="pencil" @click.stop="showFilesList=true" /></div>
              <div class="flex-none h-auto w-20 border-r border-r-1 cursor-default">
                <gcode-preview-image
                  :gcodeId="selectedGcode.id"
                  :previewExists="$filters.previewImageExists(selectedGcode.previewImages)"
                  :previewImageId="$filters.primaryPreviewImage(selectedGcode.previewImages)?.id"
                  :color="selectedGcode.filament_color"
                  :allowInteractions="false"
                ></gcode-preview-image>
              </div>
              <div class="m-2 space-y-2">
                <div class="text-sm break-all" :title="selectedGcode.name">{{ selectedGcode.name }}</div>
                <div class="text-xs font-light flex space-x-1">
                  <div>{{ selectedGcode.uploadedBy?.username }}, <time :datetime="selectedGcode?.uploadedOn">{{ $filters.humanDateFormat(selectedGcode?.uploadedOn) }}</time></div>
                  <ui-badge v-if="selectedGcode?.filament_type" color="success">{{ selectedGcode?.filament_type }}</ui-badge>
                </div>
              </div>
            </div>

            <!-- files list -->
            <div v-show="showFilesList">
              <div class="h-96 overflow-scroll overflow-x-hidden">
                <print-dialog-files-list @gcodeSelected="onGcodeSelected" :preselectedGcodeId="preselectedGcodeId" />
              </div>
            </div>
          </div>
        </div>

        <!-- PRINTERS -->
        <div v-if="!showFilesList" class="mt-8">
          <div class="flex space-x-2">
            <h3>{{ $t('components.printDialog.printersToPrintTxt') }}</h3>
            <ui-button @click.stop.prevent="reloadData()" icon="refresh" small></ui-button>
          </div>
          <div class="relative border border-1 border-gray-300 mt-2 rounded">
            <div class="py-2 pl-2 flex space-x-2 border-b border-b-1 border-gray-300">
              <ui-button icon="check" tiny @click.stop.prevent="selectAllPrinters()" class="w-5" />
              <div class="flex space-x-1 text-xs relative">
                <div class="hidden sm:block">{{ $t('components.printDialog.filterMaterialTxt') }}:</div>
                <ui-simple-dropdown :item="filamentTypeFiltered" :values="FILTER_TYPE_FILAMENT_VALUES" @clicked="onFilterChange(FILTER_TYPE_FILAMENT, $event)" />
              </div>
              <div class="flex space-x-1 text-xs">
                <div class="hidden sm:block">{{ $t('components.printDialog.filterStateTxt') }}:</div>
                <ui-simple-dropdown :item="stateFiltered" :values="FILTER_TYPE_STATE_VALUES" @clicked="onFilterChange(FILTER_TYPE_STATE, $event)" />
              </div>
              <div v-if="printersGroups?.length > 0" class="flex space-x-1 text-xs grow">
                <div class="hidden sm:block">{{ $t('components.printDialog.filterGroupTxt') }}:</div>
                <ui-simple-dropdown :item="printersGroupFiltered" :values="printersGroups" @clicked="onFilterChange(FILTER_TYPE_GROUP, $event)" class="max-w-[128px]" />
              </div>
              <div class="absolute right-1 top-1">
                <ui-button @click.stop.prevent="resetAllFilters()" v-if="filamentTypeFiltered || stateFiltered || printersGroupFiltered" small icon="x-circle" />
              </div>
            </div>

            <!-- printers list -->
            <div class="overflow-scroll overflow-x-hidden h-64">
              <ui-grid >
                <ui-grid-item
                  v-for="item in printersList"
                  :key="item.id"
                  @click="selectPrinter(item.id)"
                  class="bg-gray-100 dark:bg-gray-900 select-none relative"
                >
                  <div
                    class="absolute top-0 left-0 right-0 bottom-0 pointer-events-none"
                    :class="{
                      'opacity-25 bg-green-300': selectedPrinters.includes(item.id) && ERRORS_BY_PRINTER_ID[item.id]?.isReadyToPrint && ERRORS_BY_PRINTER_ID[item.id]?.isCorrectFilament,
                      'opacity-25 bg-red-300': selectedPrinters.includes(item.id) && (!ERRORS_BY_PRINTER_ID[item.id]?.isReadyToPrint || !ERRORS_BY_PRINTER_ID[item.id]?.isCorrectFilament),
                    }"
                  ></div>
                  <ui-grid-item-col>
                    <input type="checkbox" :checked="selectedPrinters.includes(item.id)" />
                  </ui-grid-item-col>
                  <ui-grid-item-col type="main" class="w-full pl-2 relative">
                    <div class="absolute right-0">
                      <filament-type :name="item.filamentType" allowEmptyTypeValue @typeChange="onFilamentTypeChange(item.id, $event)" :hasPermission="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP)" />
                    </div>
                    <ui-grid-item-col-content type="heading" class="w-full">
                      <div class="flex flex-col">
                        <div class="grow truncate">{{ item.name }}</div>
                        <div class=""><printer-state :printer="item" /></div>
                        <div class="text-red-400 text-xs">
                          <div v-if="!ERRORS_BY_PRINTER_ID[item.id]?.isReadyToPrint">{{ $t('components.printDialog.errorPrinterNotReady') }}</div>
                          <div v-else-if="!ERRORS_BY_PRINTER_ID[item.id]?.isCorrectFilament">{{ $t('components.printDialog.errorFilamentNotMatch') }}</div>
                        </div>
                      </div>
                    </ui-grid-item-col-content>
                  </ui-grid-item-col>
                </ui-grid-item>
              </ui-grid>
              <div class="my-4 w-full">
                <div v-if="printersList?.length <= 0" class="text-center text-sm font-light mb-4 px-4">
                  {{ $t('components.printDialog.noPrintersFiltered') }}
                </div>
                <div class="w-full text-center space-x-2">
                  <ui-button @click.stop.prevent="reloadData()" small color="primary" icon="refresh" />
                  <ui-button @click.stop.prevent="resetAllFilters()" v-if="filamentTypeFiltered || stateFiltered || printersGroupFiltered" small icon="x-circle" />
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div v-if="!uploadingFileToPrinter && !showFilesList" class="space-y-3 mt-5">
        <label class="flex cursor-pointer">
          <input
            v-model="makeVideo"
            id="makeVideo"
            name="makeVideo"
            type="checkbox"
            class="
              self-center
              h-4 w-4
              mr-2
              border border-gray-200 dark:border-gray-700 rounded-sm
              text-gray-700
              bg-white-100 dark:bg-gray-900
              focus:outline-none focus:ring-1 focus:ring-offset-transparent focus:ring-red-400
            "
          >
          <span class="text-sm text-gray-700 dark:text-gray-400">
            {{ $t('components.printDialog.generateTimelapse') }}
          </span>
        </label>
      </div>

      <div v-if="!uploadingFileToPrinter" class="mt-8 space-x-2 text-center">
        <ui-button @click="cancel()" color="default">
          {{ $t('components.modal.cancel') }}
        </ui-button>
        <ui-button @click="print()" color="primary" :disabled="!this.selectedGcode">
          {{ $t('components.printDialog.startPrint') }}
        </ui-button>
      </div>

    </div>

    <div v-if="showValidationError" class="absolute top-0 right-0 bottom-0 left-0 bg-gray-400 opacity-50"></div>
    <div v-if="showValidationError" class="absolute top-0 right-0 bottom-0 left-0 grid place-content-center" @click.stop.prevent="showValidationError = false;">
      <div class="bg-white-100 p-4 text-sm border border-1 border-red-400 rounded">
        <div class="font-bold">{{ $t('components.printDialog.validationWarning') }}</div>
        <div>{{ validationErrorText }}</div>
        <div class="grid place-content-center mt-6"><ui-button color="primary" @click.stop.prevent="showValidationError = false;">{{ $t('components.printDialog.validationDialogBtnOk') }}</ui-button></div>
      </div>
    </div>
  </ui-modal>

</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { AsyncTask } from '@/asyncTasksService';
import { KarmenError } from '@/errors';
import uiButton from '@/components/ui/uiButton.vue';
import uiGrid from '@/components/ui/uiGrid.vue';
import uiGridItem from '@/components/ui/uiGridItem.vue';
import uiGridItemCol from '@/components/ui/uiGridItemCol.vue';
import uiGridItemColContent from '@/components/ui/uiGridItemColContent.vue';
import uiBadge from '@/components/ui/uiBadge.vue';
import uiModal from '@/components/ui/uiModal.vue';
import uiLoader from '@/components/ui/uiLoader.vue';
import uiSimpleDropdown from '@/components/ui/uiSimpleDropdown.vue';
import PrinterState from '@/components/PrinterState.vue';
import PrintDialogFilesList from '@/components/PrintDialogFilesList.vue';
import GcodePreviewImage from '@/components/GcodePreviewImage.vue';
import FilamentType from '@/components/FilamentType.vue';

export default {
  name: 'PrintDialog',

  emits: [
    'close',     // dialog should be closed
    'cancelled', // when user click cancel button, nothing happend
    'started',   // print has sucessfully started
    'error',     // some error occured
    'queued-up'  // printjob was initiated on multiple printers
  ],

  components: {
    uiButton,
    uiGrid,
    uiGridItem,
    uiGridItemCol,
    uiGridItemColContent,
    uiBadge,
    uiModal,
    uiLoader,
    uiSimpleDropdown,
    PrinterState,
    PrintDialogFilesList,
    GcodePreviewImage,
    FilamentType
  },

  props: {
    preselectedGcodeId: {
      type: String,
      default: null
    },
    preselectedPrinterId: {
      type: String,
      default: null
    },
    preselectedPrinterGroupId: {
      type: String,
      default: null
    },
    show: {
      type: Boolean,
      default: false
    },
    singlePrinterMode: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    async show(newVal) {
      this.printersList = [];
      this.printersGroups = [];
      this.selectedPrinters = [];
      this.selectedGcode = null;
      this.uploadingFileToPrinter = false;
      this.showFilesList = true;
      this.isFirstRun = true;

      this.filamentTypeFiltered = null;
      this.stateFiltered = this.FILTER_TYPE_STATE_VALUES[0];
      this.printersGroupFiltered = null;

      if (newVal) {
        await this.reloadData();
      }
    }
  },

  data: () => ({
    printersList: [],
    printersGroups: [],

    // indicates, wheter dialog is freshly opened when loading data
    // on first run we want to preserve selected printers etc, not after filters are changed or data reloaded
    isFirstRun: true,

    selectedPrinters: [],
    selectedGcode: null,

    makeVideo: false,
    uploadingFileToPrinter: false,

    showFilesList: true,

    FILTER_TYPE_FILAMENT: 'filament_type',
    FILTER_TYPE_FILAMENT_VALUES: null,

    FILTER_TYPE_STATE: 'printer_state',
    FILTER_TYPE_STATE_VALUES: [{id: 'READY', name:'Ready'}],

    FILTER_TYPE_GROUP: 'printers_group',

    filamentTypeFiltered: null,
    stateFiltered: null,
    printersGroupFiltered: null,

    ERRORS_BY_PRINTER_ID: {},

    showValidationError: false,
    validationErrorText: null
  }),

  created: function() {
    this.FILTER_TYPE_FILAMENT_VALUES = this.$store.getters['app/filamentTypes'].map((item) => ({id: item, name: item}));
  },

  methods: {
    ...mapActions('printers', ['printGcode']),
    ...mapMutations('app', { setNotification: 'setNotification' }),

    async reloadData() {
      this.selectNoPrinters();

      await this.$store.dispatch('printerGroups/loadPrinterGroups');
      this.printersGroups = this.$store.getters['printerGroups/printerGroups'];

      // set preselected printer group, only first run when print dialog is opened
      if (this.preselectedPrinterGroupId && this.isFirstRun) {
        this.printersGroupFiltered = this.$store.getters['printerGroups/printerGroupById'](this.preselectedPrinterGroupId)
      }

      await this.filterPrinters();

      if (this.preselectedPrinterId && this.isFirstRun) {
        this.selectPrinter(this.preselectedPrinterId);
      }

      this.isFirstRun = false;
    },

    async filterPrinters() {
      this.printersList = await this.$store.getters['printers/printers'].filter((e) => {

        let _error = {
          isReadyToPrint: e.printerState == 'READY_TO_PRINT',
          isCorrectFilament: e.filamentType == this.selectedGcode?.filament_type
        }
        this.ERRORS_BY_PRINTER_ID[e.id] = _error;

        // filter printers with different filament type
        if (this.filamentTypeFiltered) {
          if (e.filamentType && e.filamentType != this.filamentTypeFiltered.id) {
            return false;
          }
        }

        // filter READY printers only
        if (this.stateFiltered?.id == 'READY' && !_error.isReadyToPrint) {
          return false;
        }

        // filter printers by printer group
        if (this.printersGroupFiltered) {
          let group = this.$store.getters['printerGroups/printerGroupById'](this.printersGroupFiltered.id);
          if (group.printers.find(item => item.id == e.id) == undefined) {
            return false;
          }
        }

        return true;
      });
    },

    validate() {
      // some printer must be selected
      if (!this.selectedPrinters || this.selectedPrinters?.length <= 0) {
        this.setValidationError(this.$t('components.printDialog.validationErrorNoPrinterSelected'));
        return false;
      }

      if (this.selectedPrinters) {
        for (let pId of this.selectedPrinters) {
          let _printer = this.$store.getters['printers/printerById'](pId);

          // all printers must be in ready to print state
          if (_printer.printerState != 'READY_TO_PRINT') {
            this.setValidationError(this.$t('components.printDialog.validationErrorNotAllPrintersReady'));
            return false;
          }

          // all printers must have correct filament or filament not set
          if (_printer.filamentType && this.selectedGcode.filament_type != _printer.filamentType) {
            this.setValidationError(this.$t('components.printDialog.validationErrorNotAllPrintersCorrectFilament'));
            return false;
          }
        }
      }

      return true;
    },

    setValidationError(text) {
      this.validationErrorText = text;
      this.showValidationError = true;
    },

    async print() {
      if (this.validate()) {
        this.uploadingFileToPrinter = true;

        if (this.selectedPrinters.length == 1) {
          this.$store.commit('printers/setPrinterOnStateChanging', { printerId: this.selectedPrinters[0], state: true });

          // start printjob only on one printer, we'll keep dialog opened until printjob is started
          try {
            let response = await this.printGcode({
              gcodeId: this.selectedGcode.id,
              printerId: this.selectedPrinters[0],
              makeVideo: this.makeVideo
            });

            this.$emit('started', {
              printjobId: response?.data?.printjob_id,
              gcodeId: this.selectedGcode.id,
              printerId: this.selectedPrinters[0],
              makeVideo: this.makeVideo
            });
            this.close();
          } catch (e) {
            this.$emit('error');
            this.close();
            if (e?.response?.status === 408) {
              this.setNotification({
                title: this.$t('components.printDialog.errorTimeoutTitle'),
                text: this.$t('components.printDialog.errorTimeoutDescription')
              });
            } else {
              throw e;
            }
          }

        } else {
          // start printjobs as async service tasks on multiple printers
          for (let pId of this.selectedPrinters) {
            let printer = this.$store.getters['printers/printerById'](pId);

            this.$store.commit('printers/setPrinterOnStateChanging', { printerId: printer.id, state: true });

            let callbackParams = { id: printer.id };
            let asyncTask = new AsyncTask(
              // task name
              `New printjob on "${printer.name}". ${this.selectedGcode.name}`,
              
              // promise for the task
              new Promise((resolve) => {
                this.printGcode({
                  gcodeId: this.selectedGcode.id,
                  printerId: printer.id,
                  makeVideo: this.makeVideo
                }).then(() => {
                  setTimeout(resolve, 1000);
                }).catch((e) => {
                  if (e?.response?.status === 408) {
                    this.setNotification({
                      title: this.$t('components.printDialog.errorTimeoutTitle'),
                      text: this.$t('components.printDialog.errorTimeoutDescription')
                    });
                  } else {
                    throw new KarmenError(
                      `Error when start new printjob on printer "${printer?.name}".`,
                      e?.message
                    );
                  }
                });
              }),

              () => {
                this.$router.push({ name: 'PrinterDetail', params: callbackParams });
              }
            );
            this.$asyncTasksService.addTask(asyncTask);
          }

          this.$emit('queued-up', {
            gcodeId: this.selectedGcode.id,
            printersIds: this.selectedPrinters,
            makeVideo: this.makeVideo
          });

          this.close();
        }
      }
    },

    selectPrinter(printerId) {
      // is printer in filtered printers?
      if (this.printersList.find((e) => e.id == printerId)) {

        // is printer already selected?
        let isPrinterSelected = this.selectedPrinters.indexOf(printerId);

        if (isPrinterSelected >= 0) {
          // remove printer from the list
          this.selectedPrinters.splice(isPrinterSelected, 1);
        } else {
          // in singlePrinterMode only one printer can be selected, so make sure, any other printers are removed befor the printer is added to selected printers list
          if (this.singlePrinterMode) {
            this.selectedPrinters = [];
          }
          // add printer to the list
          this.selectedPrinters.push(printerId);
        }
      }
    },

    onGcodeSelected(gcode) {
      this.showFilesList = false;
      this.selectedGcode = gcode;
      if (gcode?.filament_type) {
        this.onFilterChange(this.FILTER_TYPE_FILAMENT, { id: gcode.filament_type, name: gcode.filament_type })
      }
    },

    onFilterChange(filterType, event) {
      this.selectNoPrinters();

      if (filterType == this.FILTER_TYPE_FILAMENT) {
        this.filamentTypeFiltered = event;
      } else if (filterType == this.FILTER_TYPE_STATE) {
        this.stateFiltered = event;
      } else if (filterType == this.FILTER_TYPE_GROUP) {
        this.printersGroupFiltered = event;
      }
      this.filterPrinters();
    },

    resetAllFilters() {
      this.filamentTypeFiltered = null;
      this.stateFiltered = null;
      this.printersGroupFiltered = null;
      this.onFilterChange();
    },

    close() {
      this.uploadingFileToPrinter = false;
      this.$emit('close');
    },

    cancel() {
      this.$emit('cancelled');
      this.close();
    },

    selectAllPrinters() {
      if (this.selectedPrinters?.length == this.printersList.length) {
        // when all printers are already selected, select no printer instead
        this.selectNoPrinters();
      } else {
        this.selectedPrinters = [];
        this.printersList.forEach((p) => {
        this.selectedPrinters.push(p.id);
      });
      }
    },

    selectNoPrinters() {
      this.selectedPrinters = [];
    },

    async onFilamentTypeChange(printerId, value) {
      await this.$store.dispatch('printers/updatePrinter', { id: printerId, filamentType: value || '' });
      await this.filterPrinters();
    }
  }
}
</script>
