<template>
  <authorized-layout>
    <worskpace-group-header
      class="mt-8 sm:mt-0 mb-4"
      :groupName="$store.getters['account/currentGroup']?.name"
      :groupMembersCount="$store.getters['account/currentGroup']?.users?.length"
      :groupCreatedDate="$store.getters['account/currentGroup']?.created_on"
      :groupWebsiteUrl="$store.getters['account/currentGroup']?.public_website_url"
      :groupIsPublic="$store.getters['account/currentGroup']?.is_public"
      :groupPicture="groupPicture"
      :groupIsAdmin="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.CHANGE_GROUP)"
      :loading="false"
    />

    <!-- Printers list or no printers CTA -->
    <ui-page-section-box v-if="$store.getters['account/currentGroup']?.printers_count === 0" :tight="$isMobile()">
      <empty-state
        icon="printer"
        :title="$t('components.emptyState.printersTitle')"
        :subtitle="$t('components.emptyState.printersSubtitle')"
        :order="($store.getters['account/currentGroup']?.gcodes_count === 0) ? 1 : null"
        :ctaTitle="$t('components.emptyState.printersCtaTitle')"
        :ctaSubtitle="$t('components.emptyState.printersCtaSubtitle')"
      >
        <template v-slot:cta>
          <ui-button color='primary' @click="showAddPrinterDialog = true">
            {{ $t('views.settings.printerAdd') }}
          </ui-button>
        </template>
      </empty-state>
    </ui-page-section-box>
    <printers-list v-else />

    <!-- No gcodes yet CTA -->
    <ui-page-section-box tight class="mt-8">
      <empty-state
        v-if="$store.getters['account/currentGroup']?.gcodes_count === 0"
        class="md:p-6"
        icon="directory"
        :subtitle="$t('components.emptyState.gcodesSubtitle')"
        :order="$store.getters['account/currentGroup']?.printers_count === 0 ? 2 : null"
        :ctaTitle="$t('components.emptyState.gcodesCtaTitle')"
        :ctaSubtitle="$t('components.emptyState.gcodesCtaSubtitle')"
        >
        <template v-slot:cta>
          <ui-button @click="showUploadDialog = true">
            {{ $t('components.fileList.emptyCta') }}
          </ui-button>
        </template>
      </empty-state>
    </ui-page-section-box>

    <!-- Gcodes & Printjobs Tabs -->
    <div v-if="$store.getters['account/currentGroup']?.gcodes_count > 0">
      <ui-navigation-underline-tabs class="mt-6">
        <ui-navigation-underline-tabs-item
          @click.prevent="showGcodesTab=true; showPrintJobsTab=false;"
          :active="showGcodesTab"
          data-title="Dashboard: Latest Gcodes"
        >
          {{ $t('views.dashboard.latestGcodeTitle') }}
        </ui-navigation-underline-tabs-item>

        <ui-navigation-underline-tabs-item
          @click.prevent="showPrintJobsTab=true; showGcodesTab=false;"
          :active="showPrintJobsTab"
          data-title="Dashboard: Timelapse Videos"
        >
          {{ $t('views.dashboard.latestPrintJobs') }}
        </ui-navigation-underline-tabs-item>
      </ui-navigation-underline-tabs>

      <div>
        <div v-if="showGcodesTab">
          <ui-page-section-box tight >
            <files-list ref="filesList" :showLabels="false" :showButtons="false" :enableLoadNext="false" />
            <ui-page-section-box-follow-link routeName="Gcodes" class="py-4 ml-6" data-title="Printer Detail: Link to Gcodes">
              {{ $t('views.printerDetail.gotoGcodes') }}
            </ui-page-section-box-follow-link>
          </ui-page-section-box>
        </div>
        <div v-if="showPrintJobsTab">
          <ui-page-section-box tight>
            <print-jobs-list showPreviewImage :enableLoadNext="false" />
          </ui-page-section-box>
        </div>
      </div>
    </div>

    <add-printer-selector-modal
      :show="showAddPrinterDialog"
      :groupId="$store.getters['account/currentGroupId']"
      @close="showAddPrinterDialog = false"
    />

    <upload-gcode-dialog
      :show="showUploadDialog"
      @canceled="showUploadDialog = false"
      @uploaded="$router.push({ name: 'Gcodes' });"
    />
  </authorized-layout>
</template>

<script>
import uiButton from '@/components/ui/uiButton.vue';
import uiPageSectionBox from '@/components/ui/uiPageSectionBox.vue';
import AuthorizedLayout from '@/components/layouts/AuthorizedLayout.vue';
import AddPrinterSelectorModal from '@/components/AddPrinterSelectorModal.vue';
import EmptyState from '@/components/EmptyState.vue';
import PrintersList from '@/components/PrintersList.vue';
import UploadGcodeDialog from '@/components/UploadGcodeDialog.vue';
import WorskpaceGroupHeader from '@/components/WorskpaceGroupHeader.vue';
import FilesList from '@/components/FilesList.vue';
import PrintJobsList from '@/components/PrintJobsList.vue';
import uiPageSectionBoxFollowLink from '@/components/ui/uiPageSectionBoxFollowLink.vue';
import uiNavigationUnderlineTabs from '@/components/ui/uiNavigationUnderlineTabs.vue';
import uiNavigationUnderlineTabsItem from '@/components/ui/uiNavigationUnderlineTabsItem.vue';

export default {
  name: 'Dashboard',

  components: {
    uiButton,
    uiPageSectionBox,
    AddPrinterSelectorModal,
    AuthorizedLayout,
    EmptyState,
    PrintersList,
    UploadGcodeDialog,
    WorskpaceGroupHeader,
    FilesList,
    PrintJobsList,
    uiPageSectionBoxFollowLink,
    uiNavigationUnderlineTabs,
    uiNavigationUnderlineTabsItem
  },

  data: () => ({
    showAddPrinterDialog: false,
    showUploadDialog: false,
    groupPicture: null,
    printersList: [],
    isShowedUploadDialog: false,

    showGcodesTab: true,
    showPrintJobsTab: false
  }),

  created: async function() {
    if (this.$store.getters['account/currentGroup']?.id && this.$store.getters['account/currentGroup']?.avatar) {
      this.$store.dispatch(
        'account/downloadAvatarAsObjectURL',
        {
          groupId: this.$store.getters['account/currentGroup'].id,
          filename: this.$store.getters['account/currentGroup'].avatar
        }
      ).then((imgDataUrl) => {
        this.groupPicture = imgDataUrl;
      });
    }

    this.printersList = this.$store.getters['account/currentGroup']?.printers;
  }
}
</script>
