<template>
  <router-view />

  <async-tasks-list />

  <error-modal
    :show="showError"
    @close="closeErrorModal()"
    :error="error"
  ></error-modal>
  <notification-modal :show="showNotification" @close="closeNotificationModal" :notification="notification"></notification-modal>

  <!-- Print dialog -->
  <print-dialog
    :show="isShowedPrintDialog"
    :preselectedGcodeId="printDialogPreselectedGcodeId"
    :preselectedPrinterId="printDialogPreselectedPrinterId"
    :preselectedPrinterGroupId="printDialogPreselectedPrinterGroupId"
    :singlePrinterMode="printDialogSinglePrinterMode"
    @close="onPrintDialogEvent('close')"
    @cancelled="onPrintDialogEvent('cancelled')"
    @started="(data) => onPrintDialogEvent('started', data)"
    @error="onPrintDialogEvent('error')"
  />
</template>

<script>
import ErrorModal from '@/components/ErrorModal.vue';
import NotificationModal from '@/components/NotificationModal.vue';
import PrintDialog from '@/components/PrintDialog.vue';
import AsyncTasksList from '@/components/AsyncTasksList.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'App',

  components: {
    ErrorModal,
    NotificationModal,
    PrintDialog,
    AsyncTasksList
  },

  data: () => ({
    showError: false,
    error: null,
    showNotification: false,
    notification: null,
    isShowedPrintDialog: false,

    printDialogPreselectedGcodeId: null,
    printDialogPreselectedPrinterId: null,
    printDialogPreselectedPrinterGroupId: null,
    printDialogSinglePrinterMode: false
  }),

  computed: {
    ...mapGetters('app', ['getError']),
    ...mapGetters('app', ['getNotification']),
    ...mapGetters('account', ['user']),
  },

  watch: {
    getError(newVal) {
      this.error = newVal;
      this.showError = true;
    },
    getNotification(newVal) {
      this.notification = newVal;
      this.showNotification = true;
    },
    user(newVal) {
      this.$gtag.config({ user_id: newVal?.id || '' });
    }
  },

  created: function() {
    this.$printDialogService.setOnShowDialogRequestCallback((payload) => {
      this.printDialogPreselectedGcodeId = payload?.gcodeId;
      this.printDialogPreselectedPrinterId = payload?.printerId;
      this.printDialogPreselectedPrinterGroupId = payload?.preselectedPrinterGroupId;
      this.isShowedPrintDialog = true;
      this.printDialogSinglePrinterMode = payload?.singlePrinterMode;
    });
  },

  methods: {
    closeErrorModal() {
      this.showError = false;
      this.error = null;
    },

    closeNotificationModal() {
      this.showNotification = false;
      this.notification = null;
    },

    onPrintDialogEvent(event, data) {
      this.isShowedPrintDialog = false;
      this.$printDialogService.reportDialogEvent(event, data);
    }
  }
}
</script>
