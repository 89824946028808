<template>

  <!-- fullscreen -->
  <div
    v-if="printer && printer?.cameraEnabled && isFullscreen == true"
    class="flex fixed z-50 inset-0 overflow-y-auto bg-black-900 p-2"
    @click.stop="toggleFullscreen();"
  >
    <ui-img-rotable
      :image="image"
      :rotation="printer?.cameraRotation"
    />
    <div class="absolute top-3 right-3">
      <ui-button @click.stop="toggleFullscreen()" icon="x" color="dark"></ui-button>
    </div>
  </div>

  <!-- video / image -->
  <div
    v-if="
      printer && printer?.cameraEnabled
      && isFullscreen == false
      && (printer?.isDeviceConnectedToInternet || offlineCounter <= this.offlineCounterTreshold)
      && hasBeenEverOnline
    "
    class="flex"
    :class="image ? 'bg-black-900' : 'bg-white-100 dark:bg-gray-800'"
  >
    <div class="relative self-center overflow-hidden">
      <ui-img-rotable
        :image="image"
        :rotation="printer?.cameraRotation"
        @mouseover="onMouseOverEvent(true)"
        @mouseleave="onMouseOverEvent(false)"
        :class="{'grayscale blur-sm': !documentHasFocus}"
      />
      <!-- fullscreen expand button -->
      <div class="absolute top-1 right-1" v-if="showFullscreenIcon">
        <ui-button
          @click.stop="toggleFullscreen()"
          @mouseover="onMouseOverEvent(true)"
          @mouseleave="onMouseOverEvent(false)"
          icon="arrows-expand"
          color="dark"></ui-button>
      </div>
    </div>
  </div>

  <!-- default image -->
  <template v-else>
    <img
      :src="imagePlaceholder"
      width="640"
      height="480" />
  </template>

  <!-- light switch button -->
  <div
    v-if="
      printer && printer?.isDeviceConnectedToInternet
      && showLightIcon
      && (printer.hasKarmenLight || printer.hasSpectodaLight)
      && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)
    "
    class="absolute bottom-0 pl-1"
  >
    <ui-button
      @click.stop="switchLight()"
      @mouseover="onMouseOverEvent(true)"
      @mouseleave="onMouseOverEvent(false)"
      :icon="isSwitchingLight ? null : (printer.isLightOn ? 'light-on' : 'light-off')"
      color="dark"
      :loading="isSwitchingLight"
    />
  </div>

  <!-- camera state -->
  <div
    v-if="cameraState && ![CAMERA_STATES.OK, CAMERA_STATES.OFFLINE].includes(cameraState)"
    class="pointer-events-none flex justify-center items-center absolute top-10 right-0 left-0"
  >
    <ui-alert color="dark">
      <div class="text-center text-xs">
        <template v-if="cameraState == CAMERA_STATES.DISABLED || printer?.cameraEnabled === false">{{ $t('views.printerDetail.txtCameraDisabled') }}</template>
        <template v-else-if="cameraState == CAMERA_STATES.CONNECTING && !hasBeenEverOnline">{{ $t('views.printerDetail.txtCameraConnecting') }}</template>
        <template v-else-if="cameraState == CAMERA_STATES.ERROR">{{ $t('views.printerDetail.txtCameraError') }}</template>
      </div>
    </ui-alert>
  </div>

</template>

<script>
import { useKeypress } from 'vue3-keypress';
import arrayBufferToBase64 from '@/arrayBufferToBase64'
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { shallowRef } from 'vue';
import { KarmenError } from '@/errors';
import uiAlert from '@/components/ui/uiAlert.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiImgRotable from '@/components/ui/uiImgRotable.vue';

export default {
  name: 'PrinterCamera',

  components: {
    uiAlert,
    uiButton,
    uiImgRotable,
  },

  props: {
    id: {
      type: String,
      default: null,
      required: false
    },
    showFullscreenIcon: {
      type: Boolean,
      default: true,
      required: false
    },
    showLightIcon: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  data: () => ({
    printer: null,
    image: null,
    imagePlaceholder: '/no-image.png',

    isPollingEnabled: false,
    pollingInterval: null,
    pollingTimeoutObj: null,

    INTERVALS: {
      FAST: parseInt(process.env.VUE_APP_PRINTER_VIDEO_POLLING_INTERVAL_FAST),
      SLOW: parseInt(process.env.VUE_APP_PRINTER_VIDEO_POLLING_INTERVAL_SLOW),
      FAILING: parseInt(process.env.VUE_APP_PRINTER_VIDEO_POLLING_INTERVAL_FAILING),
    },

    http408Counter: 0,
    http408CounterThreshold: parseInt(process.env.VUE_APP_PRINTER_VIDEO_HTTP_408_COUNTER_TRESHOLD),

    offlineCounter: 0,
    offlineCounterTreshold: parseInt(process.env.VUE_APP_PRINTER_VIDEO_HTTP_408_COUNTER_TRESHOLD),

    isLoadingSnapshot: false,

    isMouseOver: false,

    hasBeenEverOnline: false,  // indicates, wheter camera/printer has been ever online since this component init

    cameraState: null,  // camera state
    CAMERA_STATES: {  // possible camera states
      OK: 'ok',  // camera is working
      CONNECTING: 'connecting',
      DISABLED: 'disabled',
      ERROR: 'error',
      OFFLINE: 'offline'
    },

    isFullscreen: false,

    isSwitchingLight: false,

    documentHasFocus: true,

    lastSnapshotEtagHeader: ''
  }),

  created: async function() {
    useKeypress({
      keyEvent: 'keydown',
      keyBinds: [{
        keyCode: 27, // 27 == ESC
        success: this.closeFullscreen,
        preventDefault: false
      }]
    });

    // set initial data
    this.pollingInterval = this.INTERVALS.FAILING;
    this.image = this.imagePlaceholder;
    this.cameraState = this.CAMERA_STATES.CONNECTING;
  },

  mounted: async function() {
    this.initPolling();
  },

  methods: {
    ...mapActions('printers', { loadCameraSnapshot: 'loadCameraSnapshot' }),
    ...mapActions('printers', ['setKarmenLed']),
    ...mapActions('printers', ['switchSpectodaFlashlight']),
    ...mapGetters('printers', { getPrinterById: 'printerById' }),
    ...mapMutations('app', { setNotification: 'setNotification' }),

    async poll() {

      if (!document.body.contains(this.$el)) {
        return false;
      }

      clearTimeout(this.pollingTimeoutObj);

      // check whether document is visible and not under ui-modal component, otherwise skip any action with content and API
      if (document.visibilityState == 'visible' && this.$store.getters['app/getOpenedUiModals']?.length == 0) {

        // when we know device is not connected to proxy, just make it offline imedeately
        if (this.printer?.printerState == 'NOT_CONNECTED_TO_PROXY_SERVER') {
          this.offlineCounter = this.offlineCounterTreshold+1;
        }

        // don't poll camera when device is not online/connected to internet
        if (this.printer?.isDeviceConnectedToInternet == false) {
          if (this.offlineCounter <= this.offlineCounterTreshold) {
            this.cameraState = this.CAMERA_STATES.CONNECTING;
          } else {
            this.cameraState = this.CAMERA_STATES.OFFLINE;
          }
          this.offlineCounter++;
          this.queuePoll();
          return;
        } else {
          this.offlineCounter = 0;  // reset offlineCounter
        }

        try {
          let cameraResponse = await this.loadCameraSnapshot({id: this.id, etag: this.lastSnapshotEtagHeader});

          if (cameraResponse?.status == 200) {
            this.lastSnapshotEtagHeader = cameraResponse.headers['etag'];

            this.hasBeenEverOnline = true;

            this.cameraState = this.CAMERA_STATES.OK;

            this.image = shallowRef('data:image/jpeg;base64,' + arrayBufferToBase64(cameraResponse.data));

            if (this.isMouseOver || this.isFullscreen) {
              this.pollingInterval = this.INTERVALS.FAST;
            } else {
              this.pollingInterval = this.INTERVALS.SLOW;
            }

            this.http408Counter = 0;

          } else if (cameraResponse?.response?.status == 304) {
            // HTTP 304 - not modified, we already have latest image that's available
            // this is implemented using Etag and if-none-match HTTP headers
            this.pollingInterval = this.INTERVALS.FAST;

          } else if (cameraResponse?.response?.status == 408) {
            if (this.http408Counter <= this.http408CounterThreshold) {
              this.cameraState = this.CAMERA_STATES.CONNECTING;
              this.pollingInterval = this.INTERVALS.FAILING;
            } else {
              this.image = this.imagePlaceholder;
              this.cameraState = this.CAMERA_STATES.OFFLINE;
              this.pollingInterval = this.INTERVALS.SLOW;
            }
            this.http408Counter++;

          } else {
            this.pollingInterval = this.INTERVALS.FAILING;
            this.cameraState = this.CAMERA_STATES.ERROR;
          }
        } catch (e) {
          this.image = this.imagePlaceholder;
          this.cameraState = this.CAMERA_STATES.ERROR;
          throw new KarmenError(
            'Camera problem',
            'Error getting camera snapshot.',
            e
          );
        }

        this.documentHasFocus = true;

      } else {
        this.documentHasFocus = false;
      }

      this.queuePoll();
    },

    queuePoll() {
      this.isLoadingSnapshot = false;
      this.pollingTimeoutObj = setTimeout(() => {
        if (!this.isLoadingSnapshot && this.isPollingEnabled) {
          this.isLoadingSnapshot = true;
          this.poll();
        }
      }, this.pollingInterval);
    },

    onMouseOverEvent(isOver) {
      this.isMouseOver = isOver;
    },

    toggleFullscreen: function() {
      this.isFullscreen = !this.isFullscreen;
    },

    closeFullscreen: function() {
      this.isFullscreen = false;
    },

    switchLight: async function() {
      if (!this.isSwitchingLight) {
        this.isSwitchingLight = true;
        try {
          if (this.printer.hasKarmenLight) {
            await this.setKarmenLed({
              printerId: this.printer.id,
              color: this.printer.isLightOn ? [0, 0, 0] : [255, 255, 255]
            });
          } else if (this.printer.hasSpectodaLight) {
            this.switchSpectodaFlashlight({printerId: this.printer.id});
          }
        } catch (e) {
          if (e.response.status == 408) {
            // is it running light service on device?
            this.setNotification({
              title: 'Light Control Error',
              text: 'There was a problem switching the light on/off. Please try again.'
            });
          } else {
            throw e;
          }
        }
        setTimeout(() => {
          this.isSwitchingLight = false;
        }, 4000);
      }
    },

    initPolling: async function() {
      // load printer data
      this.printer = this.getPrinterById()(this.id);

      if (this.printer?.cameraEnabled) {
        this.isPollingEnabled = true;
      } else {
        this.cameraState = this.CAMERA_STATES.DISABLED;
      }

      // start polling
      this.poll();
    }
  },

  unmounted: function() {
    this.isPollingEnabled = false;
    clearTimeout(this.pollingTimeoutObj);
  }
}
</script>
