<template>
  <header class="bg-white-100 dark:bg-gray-900 dark:sm:bg-gray-800 shadow fixed w-full z-50">

    <ui-announcement-bar />

    <!-- PC top menu -->
    <div v-if="!$isMobile()" class="max-w-7xl mx-auto sm:px-4 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="flex-shrink-0 flex items-center">
            <router-link
              :to="{ name: 'Dashboard' }"
              class="p-4 md:pl-2 lg:pl-0"
              @click="trackClick"
              data-title="Logo"
            >
              <img class="h-8 w-auto" src="/karmen-logo.svg" alt="Workflow" data-title="Logo">
            </router-link>
          </div>
          <nav v-if="isUserAuthorized" aria-label="Global" class="lg:ml-2 flex items-center space-x-3 sm:space-x-4">
            <router-link
              :to="{ name: 'Dashboard' }"
              class="mainMenuItem"
              @click="trackClick"
              data-title="Dashboard"
            >
              {{ $t('components.appheader.linkDashboard') }}
            </router-link>
            <router-link
              v-if="$store.getters['account/currentGroup']?.printers_count > 0"
              :to="{ name: 'Printers' }"
              class="mainMenuItem"
              @click="trackClick"
              data-title="Printers"
            >
              {{ $t('components.appheader.linkPrinters') }}
            </router-link>
            <router-link
              :to="{ name: 'Gcodes' }"
              class="mainMenuItem"
              @click="trackClick"
              data-title="Gcodes"
            >
              {{ $t('components.appheader.linkGcodes') }}
            </router-link>
            <router-link
              v-if="$store.getters['account/currentGroup']?.settings?.print_queue_enabled && $store.getters['account/currentGroup']?.printers_count > 0 && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)"
              :to="{ name: 'PrintQueue' }"
              class="mainMenuItem"
              @click="trackClick"
              data-title="PrintQueue"
            >
              {{ $t('components.appheader.linkPrintQueue') }}
            </router-link>
            <router-link
              :to="{ name: 'TimelapseVideos' }"
              class="mainMenuItem"
              @click="trackClick"
              data-title="Videos"
            >
              {{ $t('components.appheader.linkTimelapseVideos') }}
            </router-link>
          </nav>
        </div>

        <div class="flex items-center">

          <ui-button
            v-if="!isUserAuthorized"
            @click="login()"
            color="primary"
            small
            class="mr-3 sm:m-0"
            aria-label="$t('views.login.btnSignIn')"
          >{{ $t('views.login.btnSignIn') }}</ui-button>

          <template v-if="isUserAuthorized">
            <div class="space-x-2">
              <div @click="switchTheme()" class="inline-block h-4 pt-1 pr-2 cursor-pointer hover:text-red-400 dark:hover:text-red-400">
                <ui-icon v-if="this.$store.getters['app/getTheme'] == 'dark'" name="sun" class="h-4" />
                <ui-icon v-else name="moon" class="h-4" />
              </div>
              <ui-button
                v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)"
                @click="openPrintDialog(true)"
                type="button"
                color="primary"
                small
                id="user-menu"
                aria-haspopup="true"
                class="px-2"
              >
                {{ $t('views.gcodeDetail.btnPrint') }}
              </ui-button>
              <ui-button
                v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.UPLOAD_FILE)"
                @click="openUploadDialog(true)"
                type="button"
                small
                id="user-menu"
                aria-haspopup="true"
                class="px-2"
              >
                {{ $t('views.gcodes.btnUpload') }}
              </ui-button>
            </div>
            <div class="relative">
              <button
                @click="openContextMenu()"
                type="button"
                id="user-menu"
                aria-haspopup="true"
                class="btn clear px-2 ml-2 mt-1.5"
              >
                <span class="sr-only">Open user menu</span>
                <ui-icon name="dots-vertical" class="h-8 w-8" />
              </button>

              <ui-context-menu :show="isContextMenuOpen" @close="openContextMenu()">
                <ui-context-menu-item v-if="$store.getters['account/groups'].length > 1" :to="{ name: 'SelectGroup' }">{{ $t('components.appheader.linkSwitchWorkspace') }}</ui-context-menu-item>
                <ui-context-menu-item :to="{ name: 'SettingsAccount' }">{{ $t('components.appheader.linkSettings') }}</ui-context-menu-item>
                <ui-context-menu-item href="https://forum.karmen.tech/" target="_blank" @click="openContextMenu()" divider>{{ $t('components.appheader.linkCommunityForum') }}</ui-context-menu-item>
                <ui-context-menu-item href="https://karmen.tech/contact/" target="_blank" @click="openContextMenu()">{{ $t('components.appheader.linkHelpAndSupport') }}</ui-context-menu-item>
                <ui-context-menu-item @click="logout()" divider>{{ $t('components.appheader.linkLogout') }}</ui-context-menu-item>
              </ui-context-menu>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- Mobile menu -->
    <div v-if="$isMobile()" class="max-w-7xl mx-auto sm:px-4 lg:px-8">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="flex-shrink-0 flex items-center">
            <router-link
              :to="{ name: 'Dashboard' }"
              class="p-4 md:pl-2 lg:pl-0"
              @click="trackClick"
              data-title="Logo"
            >
              <img class="h-8 w-auto" src="/karmen-logo.svg" alt="Workflow" data-title="Logo">
            </router-link>
            <div class="sm:text-2xl font-bold text-gray-700 dark:text-gray-200">{{ $store.getters['account/currentGroup']?.name }}</div>
          </div>
        </div>
        <div class="flex items-center mr-2">
          <ui-button
            v-if="this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)"
            @click="openPrintDialog(true)"
            type="button"
            color="primary"
            small
            id="user-menu"
            aria-haspopup="true"
            class="px-2"
          >
            {{ $t('views.gcodeDetail.btnPrint') }}
          </ui-button>
        </div>
      </div>
      <ui-context-menu :show="isContextMenuOpen" @close="openContextMenu()">
        <ui-context-menu-item v-if="$store.getters['account/currentGroup']?.settings?.print_queue_enabled" :to="{ name: 'TimelapseVideos' }">{{ $t('components.appheader.linkTimelapseVideos') }}</ui-context-menu-item>
        <ui-context-menu-item v-if="$store.getters['account/groups'].length > 1" :to="{ name: 'SelectGroup' }">{{ $t('components.appheader.linkSwitchWorkspace') }}</ui-context-menu-item>
        <ui-context-menu-item :to="{ name: 'Settings' }">{{ $t('components.appheader.linkSettings') }}</ui-context-menu-item>
        <ui-context-menu-item @click="logout()" divider>{{ $t('components.appheader.linkLogout') }}</ui-context-menu-item>
      </ui-context-menu>
    </div>

    <div
      v-if="$isMobile() && isUserAuthorized"
      class="pl-2 pr-4 bg-white-100 dark:bg-gray-800 w-full h-18 py-2 flex justify-between fixed bottom-0 z-40 border-t border-t-1 border-gray-300 mobileMenuShadow"
    >
      <router-link
        :to="{ name: 'Dashboard' }"
        @click="trackClick"
        data-title="Dashboard"
        class="mobileMenuItem"
      >
        <ui-icon name="home" class="h-6 w-6" />
        <span class="mx-1">{{ $t('components.appheader.linkDashboard') }}</span>
      </router-link>

      <router-link
        :to="{ name: 'Printers' }"
        @click="trackClick"
        data-title="Printers"
        class="mobileMenuItem"
      >
        <ui-icon name="printer" class="h-6 w-6" />
        <span class="mx-1">{{ $t('components.appheader.linkPrinters') }}</span>
      </router-link>

      <router-link
        :to="{ name: 'Gcodes' }"
        @click="trackClick"
        data-title="Gcodes"
        class="mobileMenuItem"
      >
        <ui-icon name="directory" class="h-6 w-6" />
        <span class="mx-1">{{ $t('components.appheader.linkGcodes') }}</span>
      </router-link>

      <router-link
        v-if="$store.getters['account/currentGroup']?.settings?.print_queue_enabled && this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS)"
        :to="{ name: 'PrintQueue' }"
        @click="trackClick"
        data-title="PrintQueue"
        class="mobileMenuItem"
      >
        <ui-icon name="queue-list" class="h-6 w-6" />
        <span class="mx-1">{{ $t('components.appheader.linkPrintQueue') }}</span>
      </router-link>

      <router-link
        v-if="
          // if printque enabled, don't show video button in mobile menu unless user don't have permission to operate printers
          // which means print queue menu item is not visible and there is space for video item
          !$store.getters['account/currentGroup']?.settings?.print_queue_enabled
          || ($store.getters['account/currentGroup']?.settings?.print_queue_enabled && !this.$store.getters['account/hasPermission'](this.$PERMISSIONS.OPERATE_PRINTERS))
        "
        :to="{ name: 'TimelapseVideos' }"
        @click="trackClick"
        data-title="TimelapseVideos"
        class="mobileMenuItem"
      >
        <ui-icon name="play" class="h-6 w-6 py-0.5" />
        <span class="mx-1">{{ $t('components.appheader.linkTimelapseVideos') }}</span>
      </router-link>

      <button
        @click="openContextMenu()"
        type="button"
        id="user-menu"
        aria-haspopup="true"
        class="btn clear pb-6"
      >
        <span class="sr-only">Open user menu</span>
        <ui-icon name="dots-horizontal" class="h-8 w-8" />
      </button>
    </div>

  </header>

  <!-- Upload dialog -->
  <upload-gcode-dialog
    :show="isShowedUploadDialog"
    @canceled="openUploadDialog(false)"
    @uploaded="onFileUploaded"
  />

</template>

<style scoped>
.mainMenuItem {
  @apply
    px-3 py-2
    text-gray-700 dark:text-gray-500
    hover:text-red-400 dark:hover:text-red-400
    text-sm font-medium whitespace-nowrap select-none;
}
.mainMenuItem.router-link-active {
  @apply text-red-400 dark:text-white-100;
}
.mobileMenuItem {
  @apply
    py-1 cursor-pointer hover:bg-gray-200 hover:text-gray-700
    dark:hover:bg-gray-600 dark:hover:text-gray-200
    text-xs flex flex-col items-center text-center font-light;
}
.mobileMenuShadow {
  box-shadow: 0 -2px 4px rgb(0 0 0 / 0.1);
}
.mobileMenuShadow.dark {
  box-shadow: 0 -2px 8px rgb(255 255 255 / 0.25);
}
</style>

<script>
import uiAnnouncementBar from '@/components/ui/uiAnnouncementBar.vue';
import uiButton from '@/components/ui/uiButton.vue';
import uiIcon from '@/components/ui/uiIcon.vue';
import uiContextMenu from '@/components/ui/uiContextMenu.vue';
import uiContextMenuItem from '@/components/ui/uiContextMenuItem.vue';
import UploadGcodeDialog from '@/components/UploadGcodeDialog.vue';

export default {
  name: 'AppHeader',

  components: {
    uiAnnouncementBar,
    uiButton,
    uiIcon,
    uiContextMenu,
    uiContextMenuItem,
    UploadGcodeDialog
  },

  watch:{
    $route (){
      document.body.classList.remove('overflow-hidden');
    }
  },

  data: () => ({
    isContextMenuOpen: false,
    isUserAuthorized: false,
    isShowedUploadDialog: false,
    preselectedPrinterIdForPrintDialog: null
  }),

  created: async function() {
    // if on printer detail, let's pre-select printer in print dialog
    this.preselectedPrinterIdForPrintDialog = null;
    if (this.$route?.name == 'PrinterDetail') {
      this.preselectedPrinterIdForPrintDialog = this.$route.params.id;
    }

    try {
      await this.$store.dispatch('account/hasPermission');
      this.isUserAuthorized = true;
    } catch (e) {
      this.isUserAuthorized = false;
    }
  },

  methods: {
    logout() {
      this.$router.push({ name: 'Logout' });
    },

    login() {
      this.$router.push({ name: 'Login' });
    },

    openContextMenu(forceClose) {
      let action = !this.isContextMenuOpen;
      if (forceClose) {
        action = false;
      }
      this.isContextMenuOpen = action;
    },

    openUploadDialog(val) {
      this.isShowedUploadDialog = val;
    },

    openPrintDialog() {
      this.$printDialogService.showPrintDialog();
    },

    trackClick(e) {
      this.$gtag.event('click', {
        'event_category': 'Navigation',
        'event_label': 'Main Navigation Item',
        'value':  e.target.dataset.title
      })
    },

    onFileUploaded() {
      this.openUploadDialog(false);
      this.$router.push({ name: 'Gcodes' });
    },

    switchTheme: function() {
      if (this.$store.getters['app/getTheme'] == 'light') {
        this.$store.commit('app/setTheme', 'dark');
      } else {
        this.$store.commit('app/setTheme', 'light');
      }
    }
  }
};
</script>
